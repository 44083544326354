<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_70_4890" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" />
    </mask>
    <g mask="url(#mask0_70_4890)">
      <path
          d="M8.42857 10.5714H2.71429C2.52487 10.5714 2.34311 10.6467 2.20918 10.7806C2.07526 10.9145 2 11.0963 2 11.2857V19.8571C2 20.4256 2.22576 20.9705 2.62755 21.3724C3.0295 21.7742 3.57445 22 4.14286 22H8.42857C8.61798 22 8.79975 21.9247 8.93367 21.7908C9.0676 21.6569 9.14286 21.4751 9.14286 21.2857V11.2857C9.14286 11.0963 9.0676 10.9145 8.93367 10.7806C8.79975 10.6467 8.61798 10.5714 8.42857 10.5714ZM7.71429 20.5714H4.14286C3.95344 20.5714 3.77168 20.4962 3.63776 20.3622C3.50383 20.2283 3.42857 20.0466 3.42857 19.8571V12H7.71429V20.5714ZM19.8571 2H4.14286C3.57445 2 3.02951 2.22576 2.62755 2.62755C2.22576 3.0295 2 3.57445 2 4.14286V8.42857C2 8.61798 2.07526 8.79975 2.20918 8.93367C2.34311 9.0676 2.52487 9.14286 2.71429 9.14286H21.2857C21.4751 9.14286 21.6569 9.0676 21.7908 8.93367C21.9247 8.79975 22 8.61798 22 8.42857V4.14286C22 3.57445 21.7742 3.02951 21.3724 2.62755C20.9705 2.22576 20.4256 2 19.8571 2ZM20.5714 7.71429H3.42857V4.14286C3.42857 3.95344 3.50383 3.77168 3.63776 3.63776C3.77168 3.50383 3.95344 3.42857 4.14286 3.42857H19.8571C20.0466 3.42857 20.2283 3.50383 20.3622 3.63776C20.4962 3.77168 20.5714 3.95344 20.5714 4.14286V7.71429ZM21.2857 10.5714H11.2857C11.0963 10.5714 10.9145 10.6467 10.7806 10.7806C10.6467 10.9145 10.5714 11.0963 10.5714 11.2857V21.2857C10.5714 21.4751 10.6467 21.6569 10.7806 21.7908C10.9145 21.9247 11.0963 22 11.2857 22H19.8571C20.4256 22 20.9705 21.7742 21.3724 21.3724C21.7742 20.9705 22 20.4256 22 19.8571V11.2857C22 11.0963 21.9247 10.9145 21.7908 10.7806C21.6569 10.6467 21.4751 10.5714 21.2857 10.5714ZM20.5714 19.8571C20.5714 20.0466 20.4962 20.2283 20.3622 20.3622C20.2283 20.4962 20.0466 20.5714 19.8571 20.5714H12V12H20.5714V19.8571Z"
          />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconDashboard",
}
</script>